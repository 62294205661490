import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'AuthByDiiaFlag',
  data() {
    return {
      confirmedOwner: 0,
      deepLinkBankId: null,
    };
  },
  computed: {
    ...mapGetters({
      authDiiaStatus: 'Common/auth/diia/status',
      advId: 'Common/auth/diia/advId',
      segment: 'cabinet/segment',
      statusConfirmedCar: 'Common/auth/diia/statusConfirmedCar',
      checkedBankId: 'Common/auth/bankId/checkedBankId',
    }),
    isSegmentC2C() {
      return ['c2c'].includes(this.segment);
    },
    isConfirmedCar() {
      return this.statusConfirmedCar && this.statusConfirmedCar.includes(Number(this.advId));
    },
    shouldShowCarOwnerSection() {
      return this.isSegmentC2C && this.advId;
    },
    shouldShowDiiaOption() {
      return !this.shouldShowCarOwnerSection || this.confirmedOwner || this.isConfirmedCar;
    },
  },
  methods: {
    ...mapActions('Common/auth', {
      updateVisibleDiiaPopup: 'diia/updateVisibleDiiaPopup',
      updateVisibleDiiaSharePopup: 'diia/updateVisibleDiiaSharePopup',
      fetchConfirmedOwner: 'diia/fetchConfirmedOwner',
      fetchBankIdAuthDeepLink: 'bankId/fetchBankIdAuthDeepLink',
      fetchAuthCheckedBankId: 'bankId/fetchAuthCheckedBankId',
    }),
    async getConfirmedOwner() {
      this.confirmedOwner = await this.fetchConfirmedOwner(this.advId);
    },
    openDiiaSharePopup(point_action) {
      this.updateVisibleDiiaSharePopup({
        show: true,
        advId: this.advId || this.$route.query?.advId,
      })
      this._slonik({
        event_id: 221,
        current_point_action: 'add_diia',
        previous_point_action: point_action || 'settings',
        //add_form – ідентифікатор, відкриття поп-апу з фд
        //settings – ідентифікатор, відкриття поп-апу з налаштувань
        //listing – ідентифікатор, відкриття поп-апу з лістингу
        //my_listigs - ідентифікатор відкриття з моїх оголошень
      })
    },
    async openPopUpBankId() {
      const redirectUrl = `https://auto.ria.com${this.$route.fullPath}`;
      this.deepLinkBankId = await this.fetchBankIdAuthDeepLink({redirectUrl});
      if (this.deepLinkBankId && this.deepLinkBankId.deeplink) {
        window.location.href = this.deepLinkBankId.deeplink;
      }
      this._slonik({
        event_id: 221,
        current_point_action: 'A_openBankID',
      })
    },
  },
  mounted() {
    this.isSegmentC2C ? this.getConfirmedOwner() : this.fetchAuthCheckedBankId();
    if (this.$route.query?.authDiiaFrom) {
      if (this.authDiiaStatus) {
        window.location.href = this.$route.query?.authDiiaFrom || '';
      } else {
        this.updateVisibleDiiaPopup(true);
      }
    }
    if (this.$route.query?.advId) {
      this.openDiiaSharePopup('listing');
    }
    if (this.$route.query?.authBankId) {
      this.openPopUpBankId();
    }
  },
  i18n: require('./i18n').default,
};
